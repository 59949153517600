//================================= ENABLE/DISABLE CF7 CUSTOM SUBMIT BUTTON

let wpcf7Acceptance = $('input', '.wpcf7-acceptance:not(.optional)');
let wpcf7SubmitButton = $('.wpcf7-submit');

function enableSubmitButton() {
	if (wpcf7Acceptance.length > 0)
	{
		let isPrivacyChecked = wpcf7Acceptance.prop('checked');

		wpcf7SubmitButton.prop('disabled', !isPrivacyChecked);
	}
}

enableSubmitButton();

wpcf7Acceptance.on('change', function () {
	enableSubmitButton();
});