//================================= CUSTOMIZE CHECKBOXES AND RADIO BUTTONS LOOK & FEEL

function inputToToggle() {
	$('input[type=checkbox].toggle, .wpcf7-checkbox.toggle input[type=checkbox], .mc4wp-checkbox input[type=checkbox], input[type=radio].toggle, .wpcf7-form-control.toggle input[type=radio], .mc4wp-radio input[type=radio], .mc4wp-form-fields input[type="checkbox"], input[name=eu-laws]').each(function () {
		let toggleInput = $(this);
		let toggleInputname = $(this).attr('name');
		let toggleInputType = (typeof $(this).attr('data-type') !== 'undefined')? $(this).attr('data-type') : $(this).attr('type');
		let toggleControl = $('<span class="toggle-control toggle-control--' + toggleInputType + '"></span>');

		toggleInput.hide().after(toggleControl);

		// init state
		if (toggleInput.prop('checked'))
		{
			toggleControl.addClass('toggle-control--active');
		}
		else
		{
			toggleControl.removeClass('toggle-control--active');
		}

		// change state
		toggleInput.on('change', function (e) {
			e.stopPropagation();

			// remove the active state from the other radio inputs with the same name
			if (toggleInputType === 'radio')
			{
				$('input[name="' + toggleInputname + '"]').next().removeClass('toggle-control--active');
			}

			if (toggleInput.prop('checked'))
			{
				toggleControl.addClass('toggle-control--active');
			}
			else
			{
				toggleControl.removeClass('toggle-control--active');
			}
		});
	})
}

inputToToggle();