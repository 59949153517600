//================================= SWIPER

let slideshowSwiper = new Swiper('.slideshow .swiper-container', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});


let slideshowProdottiSwiper = new Swiper('.slideshow-prodotti .swiper-container', {
	slidesPerView: 'auto',
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});


let slideshowSettoriSwiper = new Swiper('.slideshow-settori .swiper-container', {
	speed: 900,
	slidesPerView: 'auto',
	centeredSlides: true,
	spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
	},
});


let slideshowAccessoriSwiper = new Swiper('.slideshow-accessori .swiper-container', {
	slidesPerView: 'auto',
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		992: {
			allowTouchMove: false
		}
	}
});