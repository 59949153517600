import('./components/go-to-by-scroll');

/**
 * ON DOCUMENT READY
 */

(function ($) {

	const _window = $(window);

	import('./components/bind-link-to-item');

	import('./components/check-scroll');

	import('./components/toggle-menu');

	import('./components/custom-checkbox-radio');

	import('./components/slideshows');

	import('./components/enquire');

	import('./components/popups');

	import('./components/tooltips');

	import('./components/modal-box');

	import('./components/forms');

	//$('select').selectric(); // customize select look & feel

	//import('./components/de-obfuscate-email');

	import('./components/smooth-scroll');

	//import('./components/social-share');

	//import('./components/go-to-top');

	//import('./components/equalize');

	//import('./components/resize-video-iframe');

	import('./components/toggle-accordion');

	//import('./components/search-form');

	import('./components/language-switcher');

	//import('./components/animations');

	import('./components/configuratore');

	import('./components/theme-scripts');


	/**
	 * ON LOAD
	 */

	/*_window.on('load', function(event) {
	});*/


	/**
	 * ON RESIZE
	 */

	_window.on('resize', function (event) {

		if (event.target === window)
		{
			// trigger resizeEnd event only when the window resizing ends
			if (this.resizeTO)
			{
				clearTimeout(this.resizeTO);
			}
			this.resizeTO = setTimeout(function () {
				$(this).trigger('resizeEnd');
			}, 200);
		}

	});


	/**
	 * ON RESIZE END
	 */

	// do something when the window resizing ends
	/*_window.on('resizeEnd', function() {
	});*/

	/**
	 * ON SCROLL
	 */
	/*window.addEventListener('scroll', function() {
	});*/

})(jQuery);