//================================= TOGGLE ACCORDION

import { adjustFormStepsContainerHeight } from './configuratore';

$('.module-accordion__toggle').on('click', function (e) {
	// don't change class when the user clicks on the plus/minus icon
	if (!$(e.target).hasClass('tcon'))
	{
		$('.tcon', $(this)).toggleClass('tcon-transform');
	}

	$(this).parent().toggleClass('active');

	let content = $(this).next();

	content.slideToggle(function (){
		const swiperContainer = content[0].querySelector('.swiper-container');

		if (swiperContainer !== null)
		{
			swiperContainer.swiper.update();
		}

		adjustFormStepsContainerHeight();

	});
});