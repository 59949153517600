let hotspotTooltip = {};

hotspotTooltip = tippy('.hotspot', {
	trigger:     'click mouseenter',
	theme:       'hotspot',
	maxWidth:    '272px',
	offset:      [0, 15],
	interactive: true,
	arrow:       false,
	allowHTML:   true,
	content(reference) {
		const id = reference.getAttribute('data-template');
		const template = document.getElementById(id);
		return template.innerHTML
	},
	delay: [100, 1000],
});