//================================= TOGGLE MENU

const body = $('body');

document.addEventListener('click', function (e) {
	const menuToggle = document.getElementById('menu-toggle');

	// check if menuToggle exists
	if (menuToggle === null)
	{
		return;
	}

	// return if the clicked element is not #menu-toggle nor is contained in #menu-toggle
	if (e.target !== menuToggle && !menuToggle.contains(e.target))
	{
		return;
	}

	menuToggle.classList.toggle('tcon-transform');
});

$( '#menu-toggle' ).on( 'click', function() {
	// open/close mobile menu
	body.toggleClass( 'menu-open' );

	let labelText = (body.hasClass( 'menu-open' ))? 'Chiudi' : 'Menù';
	$('.tcon-label').text(labelText);
} );

$( '#menu-close' ).on( 'click', function() {
	// close mobile menu
	body.removeClass( 'menu-open' );
} );


//================================= ADD SUB-MENU TOGGLES

$('> a', '.menu-item-has-children').append('<span class="toggle_menu_item"></span>');

$('.toggle_menu_item').on('click', function(e){
	e.preventDefault();
	//e.stopPropagation();
	$(this).parents('.menu-item').toggleClass('open').children('.sub-menu').slideToggle();
});
