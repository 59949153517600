const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';


let formStepsContainer = $('.form-steps-container');

export function adjustFormStepsContainerHeight() {
	formStepsContainer.css('min-height', $('.form-step--active', formStepsContainer).height());
}

adjustFormStepsContainerHeight();


if (body.hasClass('page-template-page-configura'))
{

	//================================= CONFIGURATORE FORM VALIDATION

	const $sections = $('.form-step__inputs');

	function curIndex() {
		// Return the current index by looking at which section has the class 'form-step--active'
		return $('.form-step--active').index();
	}

	// Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
	$sections.each(function (index, section) {
		$(section).find(':input').attr('data-parsley-group', 'block-' + index);
	});


	// Validate inputs for each step
	$(':input', formStepsContainer).on('change keyup', function () {
		const input = $(this);

		validateForm();

		copyInputValueToCF7HiddenInput(input.attr('name'), input.val());
	});


	function validateForm() {

		const activeStep = $('.form-step--active');
		const activeStepForwardButton = activeStep.find('.btn--forward');

		formStepsContainer.parsley({
			//errorClass: 'wpcf7-not-valid',
			focus:    'none',
			excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled]',
		})
			.on('form:error', function () {
				// disable forward button
				activeStepForwardButton.attr('disabled', 'disabled');
			})
			.whenValidate({
				group: 'block-' + curIndex()
			})
			.done(function () {
				// enable forward button
				activeStepForwardButton.removeAttr('disabled');
			});
	}


	$('.btn--forward', formStepsContainer).on('click', function () {
		if ($(this).prop('disabled') === false)
		{
			// if this is the last button...
			if ($(this).hasClass('form-steps-container__submit'))
			{
				// ...trigger Contact Form 7...
				$('.wpcf7-submit', '.page-template-page-configura').trigger('click');
				$('.form-step--active .btn').hide();
				$('.form-step--active .loader').show();
			}
			else
			{
				// ...else advance to the next step
				let formStep = $(this).parents('.form-step');

				moveToStep(formStep, 'next');

				if (_window.width() >= 992)
				{
					goToByScroll($('.sezione-configuratore__title:visible'), 0, 600);
				}
				else
				{
					goToByScroll($('.configuratore-img'), 0, 600);
				}
			}
		}

		return false;
	});

	// Back button
	$('.btn--back', formStepsContainer).on('click', function () {
		const formStep = $(this).parents('.form-step');

		moveToStep(formStep, 'prev');
	});


	// Hide configuratore steps and show success message on CF7 callback
	$('.wpcf7', '.page-template-page-configura').on('wpcf7invalid wpcf7mailsent wpcf7mailfailed ', function (event) {
		setTimeout(function () {
			goToByScroll($('#configura'), -100, 600);
		}, 300);

		$('.sezione-configuratore__title').css('opacity', '0');
		formStepsContainer.hide();
		$('.configuratore').addClass('configuratore--sent');
	});


	function moveToStep(formStep, direction) {
		if (direction === 'next')
		{
			formStep.removeClass('form-step--active').next().addClass('form-step--active');
		}
		else
		{
			formStep.removeClass('form-step--active').prev().addClass('form-step--active');

			const activeStep = $('.form-step--active');
			const activeStepForwardButton = activeStep.find('.btn--forward');
			activeStepForwardButton.removeAttr('disabled');
		}

		adjustFormStepsContainerHeight();
	}

	// Copy configuratore input value to Contact Form 7 hidden inputs
	function copyInputValueToCF7HiddenInput(inputName, inputValue) {
		const cf7InputName = inputName.replace('conf-', '');
		$('input[name="' + cf7InputName + '"]', '.wpcf7').val(inputValue);
	}


	$('.module-accordion', formStepsContainer).each(function () {
		const n_slides = $(this).find('input').length;

		$(this).find('.module-accordion__title span').text(n_slides);
	});

	$('input', '.form-step--4').on('change', function () {
		$(this).parents('.module-accordion').addClass('chosen');
	});


	/**
	 * ON RESIZE END
	 */
	_window.on('resizeEnd', function () {

		adjustFormStepsContainerHeight();

	});

	const configuratoreImg = $('img', '.configuratore-img');

	function changeImage(imageName) {
		configuratoreImg.on('load', function () {
			configuratoreImg.parent().removeClass('loading');
			configuratoreImg.fadeIn(400);
		});

		configuratoreImg.fadeOut(200, function () {
			configuratoreImg.parent().addClass('loading');
			configuratoreImg.attr('src', configuratoreImg.attr('data-path') + imageName);
		});
	}

	/**
	 * Actions on conf-settore change
	 */
	$('#conf-settore').on('change', function () {
		let settore = $(this).val();
		let colorazioneSelect = $('#conf-colorazione');
		let pavimentoInputSi = $('input[name=conf-pavimento][value=si]');
		let pavimentoInputNo = $('input[name=conf-pavimento][value=no]');

		// se conf-settore === magazzino, imposta conf-colorazione = bianco e conf-pavimento = no
		if (settore === 'magazzino')
		{
			// disabilita option della select conf-colorazione = trasparente e pareti-trasparenti e seleziona conf-colorazione = bianco
			$('option', colorazioneSelect).each(function () {
				if ($(this).attr('value') !== '' && $(this).attr('value') !== 'bianco')
				{
					$(this).attr('disabled', 'disabled');
				}
				else
				{
					$(this).attr('selected', 'selected').prop('selected', true);
				}
			});

			// disabilita radio button conf-pavimento = si
			pavimentoInputSi.attr('disabled', 'disabled');
			pavimentoInputSi.removeAttr('checked').prop('checked', false);
			pavimentoInputSi.next().removeClass('toggle-control--active');
			pavimentoInputSi.parent().addClass('disabled');

			// seleziona radio button conf-pavimento = no
			pavimentoInputNo.attr('checked', 'checked').prop('checked', true);
			pavimentoInputNo.next().addClass('toggle-control--active');

			// abilita bottone next
			$('.btn--forward', '.form-step--2').removeAttr('disabled');
		}
		else
		{
			// abilita tutte le option della select conf-colorazione e deseleziona conf-colorazione = bianco
			$('option', colorazioneSelect).each(function () {
				$(this).removeAttr('disabled selected').prop('selected', false);
			});

			// abilita radio button conf-pavimento = si
			pavimentoInputSi.removeAttr('disabled');
			pavimentoInputSi.parent().removeClass('disabled');

			// deseleziona radio button conf-pavimento = no
			pavimentoInputNo.removeAttr('checked').prop('checked', false);
			pavimentoInputNo.next().removeClass('toggle-control--active');

			$('.btn--forward', '.form-step--2').attr('disabled', 'disabled');
		}

		colorazioneSelect.trigger('change');
		$('input[name=conf-pavimento]').trigger('change');


		// cambia immagine in base al valore di conf-settore
		if (settore !== '')
		{
			changeImage(settore + '.png');
		}
		else
		{
			changeImage('generic.webp');
		}

		// caricamento dati in .configuratore-info
		let infoValue;
		const boxSettore = $('.configuratore-info__settore');

		if (settore !== '')
		{
			infoValue = $('option:selected', '#conf-settore').text();
			boxSettore.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('span', boxSettore).text(infoValue);
	});



	/**
	 * Step 2: colorazione teli e pavimento
	 */
	$('.btn--forward', '.form-step--2').on('click', function () {
		let altezzaSelect = $('#conf-altezza');
		let colorazione = $('#conf-colorazione').val();
		let pavimento = $('input[name=conf-pavimento]:checked').val();

		// caricamento delle option della select conf-altezza
		Object.keys(misure).forEach(function (altezza) {
			if (misure[altezza] && misure[altezza][colorazione] && misure[altezza][colorazione][pavimento])
			{
				$('option[value=' + altezza + ']', altezzaSelect).removeAttr('disabled');
			}
			else
			{
				$('option[value=' + altezza + ']', altezzaSelect).attr('disabled', 'disabled');
			}
		});

		altezzaSelect.prop('selectedIndex', 0).trigger('change');
	});


	/**
	 * Actions on conf-colorazione and conf-pavimento change
	 */
	$('#conf-colorazione, input[name=conf-pavimento]').on('change', function () {
		let colorazione = $('#conf-colorazione').val();
		let pavimento = $('input[name=conf-pavimento]:checked').val();

		if (typeof colorazione !== 'undefined' && typeof pavimento !== 'undefined' && colorazione !== '')
		{
			changeImage(colorazione + '-' + pavimento + '.png');
		}
	});


	/**
	 * Actions on conf-colorazione change
	 */
	$('#conf-colorazione').on('change', function () {
		let infoValue;
		let colorazione = $(this).val();
		const boxTeli = $('.configuratore-info__teli');

		// caricamento dati in .configuratore-info
		if (colorazione !== '')
		{
			infoValue = $('option:selected', '#conf-colorazione').text();
			boxTeli.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('span', boxTeli).text(infoValue);
	});


	/**
	 * Actions on conf-pavimento change
	 */
	$('input[name=conf-pavimento]').on('change', function () {
		let infoValue;
		let pavimento = $('input[name=conf-pavimento]:checked').val();
		const boxPavimento = $('.configuratore-info__pavimento');

		// caricamento dati in .configuratore-info
		if (typeof pavimento !== 'undefined')
		{
			infoValue = $('input[name=conf-pavimento]:checked').parent().text();
			boxPavimento.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('span', boxPavimento).text(infoValue);
	});



	/**
	 * Step 3: dimensioni
	 */
	$('.btn--forward', '.form-step--3').on('click', function () {
		let settore = $('#conf-settore').val();
		let colorazione = $('#conf-colorazione').val();
		let pavimento = $('input[name=conf-pavimento]:checked').val();

		// nascondi tutto l'accordion se non contiente valori che corrispondono al settore scelto
		$('.module-accordion', '.form-step--4').each(function () {
			let accordion = $(this);
			let show = true;

			if (accordion.attr('data-settori').includes(settore))
			{
				accordion.show();
				show = show && true;
			}
			else
			{
				accordion.hide();
				show = show && false;
			}

			if (accordion.attr('data-name') === 'acc-pavimento' && pavimento === 'si')
			{
				accordion.show();
				show = show && true;
			}
			else if (accordion.attr('data-name') === 'acc-pavimento' && pavimento === 'no')
			{
				accordion.hide();
				show = show && false;
			}

			// nascondi le slide che non contengono valori che corrispondono al settore scelto
			$('.swiper-slide', accordion).each(function () {
				let slideSettori = $(this).attr('data-settori');
				let slideColorazioni = $(this).attr('data-colorazioni');

				if (slideSettori === 'all' || slideSettori.includes(settore))
				{
					show = show && true;
				}
				else
				{
					show = show && false;
				}

				if (slideColorazioni === 'all' || slideColorazioni.includes(colorazione))
				{
					show = show && true;
				}
				else
				{
					show = show && false;
				}

				if (show)
				{
					$(this).show();
					$('input', $(this)).removeAttr('disabled');
				}
				else
				{
					$(this).hide();
					$('input', $(this)).attr('disabled', 'disabled');
				}
			});
		});

		formStepsContainer.parsley().reset();
	});


	function changeImageByDimensions() {
		let colorazione = $('#conf-colorazione').val();
		let pavimento = $('input[name=conf-pavimento]:checked').val();
		let altezza = $('#conf-altezza').val();
		let larghezza = $('#conf-larghezza').val();
		let lunghezza = $('#conf-lunghezza').val();

		if (altezza !== '' && larghezza !== '' && lunghezza !== '')
		{
			let colorazioneFilename = colorazione;

			if (colorazione === 'trasparente')
			{
				colorazioneFilename = 'trasp';
			}

			if (colorazione === 'pareti-trasparenti')
			{
				colorazioneFilename = 'trasplat';
			}

			let filename = larghezza + '_' + lunghezza + '_h' + altezza + '_pav' + pavimento + '_' + colorazioneFilename + '.png';

			changeImage(colorazione + '/' + pavimento + '/' + filename);
		}
	}


	$('#conf-altezza').on('change', function () {
		let infoValue;
		const boxDimensioni = $('.configuratore-info__dimensioni');
		let larghezzaSelect = $('#conf-larghezza');
		let lunghezzaSelect = $('#conf-lunghezza');
		let altezza = $(this).val();

		if (altezza !== '')
		{
			let colorazione = $('#conf-colorazione').val();
			let pavimento = $('input[name=conf-pavimento]:checked').val();

			let larghezze = Object.keys(misure[altezza][colorazione][pavimento]);
			let larghezzeOptions = $('option:first-child', larghezzaSelect).get(0).outerHTML;

			larghezze.forEach(element => {
				larghezzeOptions += '<option value="' + element + '">' + element + ' m </option>';
			});

			larghezzaSelect.html(larghezzeOptions).removeAttr('disabled');
			larghezzaSelect.prop('selectedIndex', 0);
		}
		else
		{
			larghezzaSelect.prop('selectedIndex', 0).attr('disabled', 'disabled');
		}

		lunghezzaSelect.prop('selectedIndex', 0).attr('disabled', 'disabled');

		// caricamento dati in .configuratore-info
		if (altezza !== '')
		{
			infoValue = altezza;
			boxDimensioni.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('div:nth-of-type(1) span', boxDimensioni).text(infoValue);

		// cambia immagine
		changeImageByDimensions();
	});


	$('#conf-larghezza').on('change', function () {
		let infoValue;
		const boxDimensioni = $('.configuratore-info__dimensioni');
		let lunghezzaSelect = $('#conf-lunghezza');
		let altezza = $('#conf-altezza').val();
		let larghezza = $(this).val();

		if (larghezza !== '')
		{
			let colorazione = $('#conf-colorazione').val();
			let pavimento = $('input[name=conf-pavimento]:checked').val();

			let lunghezze = misure[altezza][colorazione][pavimento][larghezza];
			let lunghezzeOptions = $('option:first-child', lunghezzaSelect).get(0).outerHTML;

			lunghezze.forEach(element => {
				lunghezzeOptions += '<option value="' + element + '">' + element + ' m </option>';
			});

			lunghezzaSelect.html(lunghezzeOptions).removeAttr('disabled');
		}
		else
		{
			lunghezzaSelect.attr('disabled', 'disabled');
		}

		// caricamento dati in .configuratore-info
		if (larghezza !== '')
		{
			infoValue = larghezza;
			boxDimensioni.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('div:nth-of-type(2) span', boxDimensioni).text(infoValue);

		// cambia immagine
		changeImageByDimensions();
	});


	$('#conf-lunghezza').on('change', function () {
		let infoValue;
		const boxDimensioni = $('.configuratore-info__dimensioni');
		let lunghezza = $(this).val();

		// caricamento dati in .configuratore-info
		if (lunghezza !== '')
		{
			infoValue = lunghezza;
			boxDimensioni.css('opacity', 1);
		}
		else
		{
			infoValue = '';
		}

		$('div:nth-of-type(3) span', boxDimensioni).text(infoValue);

		// cambia immagine
		changeImageByDimensions();
	});



	/**
	 * Step 4: accessori
	 */
	$('.btn--forward', '.form-step--4').on('click', function () {
		const boxAccessori = $('.configuratore-info__accessori');
		let n = 0;

		$('input:checked', $('.form-step--4')).each(function () {
			if ($(this).val() !== 'nessuno')
			{
				n++;
			}
		});

		$('div:nth-of-type(1) span', boxAccessori).text(n);

		boxAccessori.css('opacity', 1);
	});



	/**
	 * Step 5: dati
	 */
	const tipologiaPreventivoInput = $('input[name="conf-preventivo"]');
	const montaggioInput = $('input[name="conf-montaggio"]');
	const localitaMontaggioInput = $('input[name="conf-localita-montaggio"]');

	tipologiaPreventivoInput.on('change', function () {

		manageConfiguratorePreventivoFields($(this).val());

	});

	function manageConfiguratorePreventivoFields(tipologiaPreventivoVal) {

		if (tipologiaPreventivoVal === 'noleggio')
		{
			$('.acquisto-fields').hide();
			$('.noleggio-fields').show();
			$('.localita-montaggio-inputs').show();

			$('input', '.acquisto-fields').removeAttr('required');
			$('input', '.noleggio-fields').attr('required', 'required');
			$('input', '.localita-montaggio-inputs').attr('required', 'required');
		}
		else if (tipologiaPreventivoVal === 'acquisto')
		{
			$('.noleggio-fields').hide();
			$('.acquisto-fields').show();
			$('.localita-montaggio-inputs').hide();

			$('input', '.noleggio-fields').removeAttr('required aria-required');
			$('input', '.acquisto-fields').attr('required', 'required');
			$('input', '.localita-montaggio-inputs').removeAttr('required');
		}
		else
		{
			$('.noleggio-fields').hide();
			$('.acquisto-fields').hide();
			$('.montaggio-inputs').hide();
			$('.localita-montaggio-inputs').hide();
		}

		montaggioInput.prop('checked', false);
		montaggioInput.next().removeClass('toggle-control--active');

		// reset form values
		copyInputValueToCF7HiddenInput('conf-montaggio', '');
		localitaMontaggioInput.val('').trigger('keyup');
		$('input[name="conf-data-inizio"]').val('').trigger('keyup');
		$('input[name="conf-data-fine"]').val('').trigger('keyup');
		$('input[name="conf-data-consegna"]').val('').trigger('keyup');

		validateForm();

		adjustFormStepsContainerHeight();
	}


	montaggioInput.on('change', function () {

		if ($(this).val() === 'si')
		{
			$('.localita-montaggio-inputs').show();

			$('input', '.localita-montaggio-inputs').attr('required', 'required');
		}
		else
		{
			$('.localita-montaggio-inputs').hide();

			$('input', '.localita-montaggio-inputs').removeAttr('required');
		}

		// reset form values
		localitaMontaggioInput.val('').trigger('keyup');

		validateForm();

		adjustFormStepsContainerHeight();
	});


	/**
	 * Campo data-inizio: data minima = oggi + 1 giorno
	 * Campo data-fine: data minima = data-inizio + 1 giorno
	 */
	const inizio = $('input[name="conf-data-inizio"]');
	const fine = $('input[name="conf-data-fine"]');

	const date = new Date();

	/*const todayDate =
		date.getFullYear().toString() + '-' +
		(date.getMonth() + 1).toString().padStart(2, '0') + '-' +
		date.getDate().toString().padStart(2, '0');*/

	const tomorrowDate =
		      date.getFullYear().toString() + '-' +
		      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
		      (date.getDate() + 1).toString().padStart(2, '0');

	const afterTomorrowDate =
		      date.getFullYear().toString() + '-' +
		      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
		      (date.getDate() + 2).toString().padStart(2, '0');

	// set initial data-inizio date
	inizio[0].value = tomorrowDate;
	inizio[0].setAttribute('min', tomorrowDate);

	// set initial data-fine date
	fine[0].value = afterTomorrowDate;
	fine[0].setAttribute('min', afterTomorrowDate);

	// set data-fine date on data-inizio date change
	inizio.on('change', function() {
		const fromDateValue = $(this).val();
		const toDate = new Date(fromDateValue);

		// add 1 day
		toDate.setDate(toDate.getDate() + 1);

		const sDate = toDate.getFullYear().toString() + '-' + (toDate.getMonth() + 1).toString().padStart(2, '0') + '-' + (toDate.getDate()).toString().padStart(2, '0');

		fine.val(sDate);
		fine[0].setAttribute('min', sDate);
		fine.trigger('change');
	});


	const misure = {
		3: {
			'bianco':             {
				'no': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			},
			'trasparente':        {
				'no': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			},
			'pareti-trasparenti': {
				'no': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			}
		},
		4: {
			'bianco':             {
				'no': {
					6:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			},
			'trasparente':        {
				'no': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			},
			'pareti-trasparenti': {
				'no': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				},
				'si': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					30: [30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			}
		},
		5: {
			'bianco': {
				'no': {
					8:  [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					10: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					12: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					15: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					20: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
					25: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
				}
			}
		}
	}

}